import {
  IntegrationDto,
  IntegrationKind,
  RestApiDatasourceConfiguration,
  SupersetIntegrationDto,
} from "@superblocksteam/shared";
import { isEmpty } from "lodash";
import { createCachedSelector } from "re-reselect";
import { createSelector } from "reselect";
import slice, { DatasourceDtoViews } from "./slice";
import { metadataSelectorKey } from "./utils";

const isPluginIntegration = (integration: DatasourceDtoViews) => {
  return !integration?.kind || integration?.kind === IntegrationKind.PLUGIN;
};

export const selectDatasourcesOfKind = createCachedSelector(
  slice.selector,
  (state: any, kind: IntegrationKind) => kind,
  (state, kind) => Object.values(state.entities).filter((e) => e.kind === kind),
)((state, kind) => kind);

export const selectSecretDatasources = createSelector(slice.selector, (state) =>
  Object.fromEntries(
    Object.entries(state.entities)
      .filter(([k, e]) => e.kind === IntegrationKind.SECRET)
      .map(
        ([k, e]) => [k, e?.integration] as [string, IntegrationDto | undefined],
      )
      .filter(
        (pair): pair is [string, IntegrationDto] => pair[1] !== undefined,
      ),
  ),
);

export const selectSecretDatasourcesAsList = createSelector(
  slice.selector,
  (state) =>
    Object.values(state.entities).filter(
      (e) => e.kind === IntegrationKind.SECRET,
    ),
);

export const selectPluginDatasources = createSelector(slice.selector, (state) =>
  Object.fromEntries(
    Object.entries(state.entities)
      .filter(([k, e]) => isPluginIntegration(e))
      .map(
        ([k, e]) =>
          [k, e?.integration] as [string, SupersetIntegrationDto | undefined],
      )
      .filter(
        (pair): pair is [string, SupersetIntegrationDto] =>
          pair[1] !== undefined,
      ),
  ),
);

// selectBuildPluginDatasources returns the SupersetIntegrationDto of datasources
// that the current user has BUILD permissions on.
export const selectBuildPluginDatasources = createSelector(
  slice.selector,
  (state) =>
    Object.fromEntries(
      Object.entries(state.entities)
        .filter(([k, e]) => isPluginIntegration(e))
        .map(
          ([k, e]) =>
            [k, e?.integration] as [string, SupersetIntegrationDto | undefined],
        )
        .filter(
          (pair): pair is [string, SupersetIntegrationDto] =>
            pair[1] !== undefined && !pair[1].readOnly,
        ),
    ),
);

export const selectEditablePluginDatasources = createSelector(
  slice.selector,
  (state) =>
    Object.fromEntries(
      Object.entries(state.entities)
        .filter(([k, e]) => isPluginIntegration(e))
        .map(
          ([k, e]) =>
            [k, e?.integration] as [string, SupersetIntegrationDto | undefined],
        )
        .filter(
          (pair): pair is [string, SupersetIntegrationDto] =>
            pair[1] !== undefined && pair[1].editable,
        ),
    ),
);

export const selectBuildPluginDatasourcesAsList = createSelector(
  selectBuildPluginDatasources,
  (state) => Object.values(state),
);

export const selectPluginDatasourcesAsList = createSelector(
  slice.selector,
  (state) => Object.values(state.entities).filter(isPluginIntegration),
);

export const selectDatasourceMetaById = createCachedSelector(
  slice.selector,
  (state: any, datasourceId: string) => datasourceId,
  (state, datasourceId) => state.meta[datasourceId],
)((state, datasourceId) => datasourceId);

export const selectDatasourceById = createCachedSelector(
  slice.selector,
  (state: any, datasourceId: string | undefined) => datasourceId,
  (state, datasourceId) => {
    return datasourceId ? state.entities[datasourceId]?.integration : undefined;
  },
)((state, datasourceId) => datasourceId ?? "undefined");

export const getOrgHasNotCreatedIntegration = createSelector(
  slice.selector,
  (state) =>
    Object.values(state.entities).every((e) =>
      Boolean(
        e.integration?.demoIntegrationId || !e.integration?.isUserConfigured,
      ),
    ),
);

export const selectMetadataLoadingById = createCachedSelector(
  slice.selector,
  (state: any, datasourceId: string) => datasourceId,
  (state, datasourceId) => state.loading[metadataSelectorKey(datasourceId)],
)((state, datasourceId) => datasourceId);

export const selectOpenApiRefByDatasourceId = createCachedSelector(
  slice.selector,
  (state: any, datasourceId: string) => datasourceId,
  (state, datasourceId) => {
    for (const configuration of state.entities[datasourceId]?.integration
      ?.configurations ?? []) {
      const openApiSpecRef = (
        configuration.configuration as RestApiDatasourceConfiguration
      )?.openApiSpecRef;
      if (openApiSpecRef) {
        return openApiSpecRef;
      }
    }
    return undefined;
  },
)((state, datasourceId) => datasourceId ?? "undefined");

export const selectHasConnectedTokens = createCachedSelector(
  slice.selector,
  (state: any, integrationConfigurationId: string, integrationId: string) =>
    integrationConfigurationId,
  (state: any, integrationConfigurationId: string, integrationId: string) =>
    integrationId,
  (state, integrationConfigurationId, integrationId) => {
    const tokens =
      state.meta[integrationId]?.metadata?.connectedUserTokens || {};
    const tokensForIntegrationConfigurationId =
      tokens[integrationConfigurationId];
    return !isEmpty(tokensForIntegrationConfigurationId);
  },
)(
  (state, integrationConfigurationId, integrationId) =>
    `${integrationConfigurationId ?? "undefined"}_${
      integrationId ?? "undefined"
    }`,
);

export const selectSecretStoreMetadata = createCachedSelector(
  slice.selector,
  selectSecretDatasourcesAsList,
  (state, secretDatasources) => {
    return {
      sb_secrets: secretDatasources.reduce(
        (accum: Record<string, Record<string, string>>, datastore) => {
          if (datastore?.integration?.slug && datastore?.integration?.id) {
            accum[datastore.integration.slug] = (
              state.meta?.[datastore.integration.id]?.metadata?.secrets ?? []
            ).reduce((accum: Record<string, string>, secret) => {
              if (secret.alias) {
                accum[secret.alias] = "";
              }
              return accum;
            }, {} as Record<string, string>);
          }
          return accum;
        },
        {},
      ),
    };
  },
)((state, integrationId) => integrationId ?? "undefined");
