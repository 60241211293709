import { Dimension } from "@superblocksteam/shared";
import React from "react";
import { GridDefaults } from "legacy/constants/WidgetConstants";
import { GeneratedTheme } from "legacy/themes";
import { CLASS_NAMES } from "legacy/themes/classnames";
import { styleAsClass } from "styles/styleAsClass";
import { CheckboxInput } from "../Shared/CheckboxInput";
import {
  type TypographyProps,
  useMinHeightFromTypography,
  useTypographyStyling,
} from "../typographyHooks";
import {
  createStyleClassName,
  getLineHeightInPxFromTextStyle,
} from "../typographyUtils";
import { DEFAULT_CHECKBOX_WIDGET_TEXT_STYLE_VARIANT } from "./constants";
import type {
  CheckboxComponentProps,
  CheckboxComponentWithLayoutManagedProps,
} from "./types";

const CheckboxContainerClass = styleAsClass`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    align-items: center;
`;

export const StaticTypographyCheckboxLabelProps: TypographyProps = {
  textStyleVariant: DEFAULT_CHECKBOX_WIDGET_TEXT_STYLE_VARIANT,
};

class CheckboxComponent extends React.PureComponent<CheckboxComponentProps> {
  getLabelClass(textStyleVariant: string | undefined) {
    return createStyleClassName({
      textStyleVariant,
      isDisabled: this.props.isDisabled,
      isLoading: this.props.isLoading,
      type: "label",
    });
  }

  render() {
    const labelClassName = this.getLabelClass(
      this.props.labelProps?.textStyleVariant,
    );

    const restOfLabel = this.props.label;
    const asterisk = "* ";
    return (
      <div
        className={CheckboxContainerClass}
        data-test={
          this.props.isChecked ? "checkbox-checked" : "checkbox-not-checked"
        }
        onClick={() => {
          if (this.props.isDisabled) return;
          this.onCheckChange();
        }}
        style={{
          cursor: this.props.isDisabled ? "not-allowed" : "pointer",
          overflowY: "hidden",
          minHeight: this.props.minHeight,
        }}
      >
        <CheckboxInput
          isChecked={this.props.isChecked}
          isDisabled={this.props.isDisabled === true}
          ariaLabel={restOfLabel}
          isValid={!this.props.isInvalid}
        />
        <label
          data-test="checkbox-label"
          className={labelClassName}
          style={this.props.labelProps?.style}
        >
          {this.props.isRequired && (
            <span className={CLASS_NAMES.ERROR_MODIFIER}>{asterisk}</span>
          )}
          {restOfLabel}
        </label>
      </div>
    );
  }
  onCheckChange = () => {
    this.props.onCheckChange(!this.props.isChecked);
  };
}

export default CheckboxComponent;

export const CheckboxComponentWithLayoutManaged = (
  props: CheckboxComponentWithLayoutManagedProps,
) => {
  const labelProps = useTypographyStyling({
    textStyle: props.labelProps?.textStyle,
    defaultTextStyleVariant: DEFAULT_CHECKBOX_WIDGET_TEXT_STYLE_VARIANT,
  });

  const minHeight = useMinHeightFromTypography({
    isAutoHeight: props.height.mode === "fitContent",
    isAutoWidth: props.width.mode === "fitContent",
    textStyleVariant: labelProps.textStyleVariant as unknown as any,
    textStyle: props.labelProps?.textStyle,
    defaultTextStyleVariant: DEFAULT_CHECKBOX_WIDGET_TEXT_STYLE_VARIANT,
  });

  return (
    <CheckboxComponent
      labelProps={labelProps}
      isRequired={props.isRequired}
      isInvalid={props.isRequired && !props.isChecked && props.isTouched}
      isChecked={!!props.isChecked}
      label={props.label}
      widgetId={props.widgetId}
      key={props.widgetId}
      isDisabled={props.isDisabled}
      onCheckChange={props.onCheckChange}
      isLoading={props.isLoading}
      minHeight={minHeight}
    />
  );
};

export const estimateInitialCheckboxWidgetHeightGU = (
  typographyEnabled: boolean,
  theme?: GeneratedTheme,
): number | undefined => {
  if (!theme?.typographies || !typographyEnabled) {
    return undefined;
  }

  const textHeight = getLineHeightInPxFromTextStyle({
    textStyleVariant: DEFAULT_CHECKBOX_WIDGET_TEXT_STYLE_VARIANT,
    nestedProps: undefined,
    typographies: theme.typographies,
    defaultTextStyleVariant: DEFAULT_CHECKBOX_WIDGET_TEXT_STYLE_VARIANT,
  });

  return Dimension.toGridUnit(
    Dimension.px(textHeight),
    GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
  ).roundUp().value;
};
