import { ApiSettings } from "@superblocksteam/shared";
import { call, put, select } from "redux-saga/effects";
import envs from "env";
import { API_STATUS_CODES } from "legacy/constants/ApiConstants";
import { ReduxActionTypes } from "legacy/constants/ReduxActionConstants";
import selectLastSuccessfulWrite from "legacy/selectors/successfulWriteSelector";
import { lock } from "store/slices/apisShared/sharedPersistApiLock";
import { Flag, selectFlagById } from "store/slices/featureFlags";
import { HttpError, EntitiesErrorType } from "store/utils/types";
import logger from "utils/logger";
import { createSaga, SagaType } from "../../../utils/saga";
import { clearV2DirtyState } from "../actions";
import { persistV2ApiSettings } from "../client";
import slice from "../slice";

export type PersistApiSettingsPayload = {
  id: string;
  settings?: ApiSettings;
  folderId?: string | null;
  updated?: Date;
};

function* persistV2ApiSettingsInternal({
  id,
  settings,
  folderId,
  updated: lastUpdated,
}: PersistApiSettingsPayload) {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  let unlock = () => {};
  try {
    unlock = yield call(lock, id);
    yield put(clearV2DirtyState.create({ id }));
    const lastSuccessfulWrite: Date = yield select(selectLastSuccessfulWrite);
    const superblocksSupportUpdateEnabled: boolean = yield select(
      selectFlagById,
      Flag.ENABLE_SUPERBLOCKS_SUPPORT_UPDATES,
    );

    const result: Awaited<ReturnType<typeof persistV2ApiSettings>> = yield call(
      persistV2ApiSettings,
      {
        id,
        settings,
        folderId,
        lastSuccessfulWrite:
          Number(lastSuccessfulWrite) === -1
            ? lastUpdated
            : lastSuccessfulWrite,
        superblocksSupportUpdateEnabled,
      },
    );

    yield put({
      type: ReduxActionTypes.UPDATE_LAST_SUCCESSFUL_WRITE,
      payload: result.updated,
    });

    return result;
  } catch (err) {
    logger.debug(`Errored when persisting the API settings: ${err}`);
    throw err;
  } finally {
    unlock();
  }
}

const convertProfileSettingsToApiPbProfileModes = (settings: ApiSettings) => {
  const profiles = {
    editor: {
      default: settings.profiles.editor.defaultProfileId,
      available: settings.profiles.editor.availableProfileIds,
    },
    deployed: {
      default: settings.profiles.deployed.defaultProfileId,
      available: settings.profiles.deployed.availableProfileIds,
    },
    preview: {
      default: "",
      available: [],
    },
  };
  return profiles;
};

export const persistV2ApiSettingsSaga = createSaga(
  persistV2ApiSettingsInternal,
  "persistV2ApiSettingsSaga",
  {
    sliceName: slice.name,
    type: SagaType.Throttled,
    delay: Number(envs.get("SUPERBLOCKS_UI_PERSIST_API_DEBOUNCE_MS")),
    keySelector: (payload) => payload.id,
  },
);

slice.saga(persistV2ApiSettingsSaga, {
  start(state, { payload }) {
    state.meta[payload.id] = state.meta[payload.id] ?? {};
    state.meta[payload.id].saving = true;
    state.meta[payload.id].editedSinceLastExecution = true;
  },
  success(state, { payload, meta }) {
    if (!payload) {
      return;
    }
    if (!state.meta[meta.args.id].isUpdating) {
      const newEntityState = {
        ...state.entities[meta.args.id],
        settings: payload,
        updated: new Date(payload.updated),
      };
      if (newEntityState?.apiPb?.trigger?.workflow?.options?.profiles?.modes) {
        newEntityState.apiPb.trigger.workflow.options.profiles.modes =
          convertProfileSettingsToApiPbProfileModes(payload);
      }
      if (newEntityState?.apiPb?.trigger?.job?.options?.profiles?.modes) {
        newEntityState.apiPb.trigger.job.options.profiles.modes =
          convertProfileSettingsToApiPbProfileModes(payload);
      }
      state.entities[meta.args.id] = newEntityState;
    }
    delete state.meta[meta.args.id].saving;
    delete state.errors[meta.args.id];
    state.meta[meta.args.id].savingFailuresCount = 0;
  },
  error(state, { payload, meta }) {
    state.meta[meta.args.id].dirty = true;
    state.errors[meta.args.id] = {
      error: payload,
      type: EntitiesErrorType.SAVE_ERROR,
    };
    delete state.meta[meta.args.id].saving;
    state.meta[meta.args.id].savingFailuresCount =
      (state.meta[meta.args.id].savingFailuresCount ?? 0) + 1;

    if (
      payload instanceof HttpError &&
      payload.code === API_STATUS_CODES.RESOURCE_CONFLICT
    ) {
      state.errors.stale = { error: payload };
    }
  },
});
