import {
  ActionType,
  ApiNotificationConfiguration,
  ApiSettings,
  BranchDto,
  DatasourceEnvironments,
  HttpMethod,
  IntegrationDto,
  PlaceholdersInfo,
  ScheduleConfig,
  ScheduleState,
} from "@superblocksteam/shared";
import {
  EventType,
  ExecuteActionCallStack,
} from "legacy/constants/ActionConstants";
import { RepoConnection } from "legacy/constants/ReduxActionConstants";
import type { Api as ApiV2 } from "../apisV2/backend-types";

export enum PaginationTypeDto {
  NONE,
  PAGE_NO,
  URL,
}

export type PropertyDto = {
  key?: string;
  value?: string;
  editable?: boolean;
  internal?: boolean;
  description?: string;
  mandatory?: boolean;
  type?: string;
  defaultValue?: string;
  minRange?: string;
  maxRange?: string;
  valueOptions?: string[];
};

// When updating, make sure it matches with:
// packages/shared/src/types/api/action.ts:224
export type ActionConfigurationDto = {
  httpMethod?: HttpMethod;
  path?: string;
  headers?: PropertyDto[];
  params?: PropertyDto[];
  body?: string;
  bodyFormData?: PropertyDto[];
  routeParams?: PropertyDto[];
  timeoutInMillisecond?: number;
  encodeParams?: boolean;
  paginationType?: PaginationTypeDto;
  next?: string;
  prev?: string;
  pluginSpecifiedTemplates?: PropertyDto[];
  urlBase?: string;
  urlPath?: string;
  filterBy?: string[];
  superblocksMetadata?: {
    pluginVersion?: string;
  };
  openApiAction?: string;
};

export type ActionSettingsDto = {
  executeOnLoad?: boolean;
  cacheResponse?: string;
  userSetOnLoad?: boolean;
  confirmBeforeExecute?: boolean;
};

export type ActionDto = {
  id: string;
  name: string;
  type: ActionType;
  configuration: ActionConfigurationDto;
  applicationId?: string;
  organizationId?: string;
  datasourceId?: string;
  pluginId?: string;
  settings?: ActionSettingsDto;
  children?: {
    [key: string]: string;
  };
};

export enum ApiTriggerType {
  UI,
  WORKFLOW,
  SCHEDULE,
}

export type ActionsDto = {
  name: string;
  triggerActionId: string;
  schedule?: ScheduleConfig;
  actions: {
    [key: string]: ActionDto;
  };
  bindings: string[];
  triggerType?: ApiTriggerType;
  executeOnPageLoad?: boolean;
  notificationConfig?: ApiNotificationConfiguration;
  workflowQueries?: ExecutionParamDto[]; // Workflow query params
  workflowParams?: ExecutionParamDto[]; // Workflow body
  supportedMethod?: "GET" | "POST";
};

export type ApiDto = {
  id: string;
  name?: string;
  apiPb?: ApiV2;
  environment?: DatasourceEnvironments;
  deployedCommitId?: string;
  applicationId?: string;
  organizationId?: string;
  actions?: ActionsDto;
  updated?: Date;
  scheduleState?: ScheduleState;
  liveSchedule?: ScheduleConfig;
  folderId?: string | null;
  isDeployed?: boolean;
  canDelete?: boolean;
  sendEmailOnFailure?: boolean;
  integrations?: Record<string, IntegrationDto>;
  creator?: {
    id: string;
    name: string;
  };
  settings?: ApiSettings;
  triggerType?: ApiTriggerType;
  repoConnection?: RepoConnection;
  currentBranch?: BranchDto;
};

export type ExecutionParamDto = {
  key: string;
  value: unknown;
};
export type ExecutionOutputDto = {
  error?: {
    /* The `fullErrorMessage` property is a human readable message with full path context */
    fullErrorMessage: string;
    message: string;
    formPath: string;
    handled?: boolean; // only for v2 api
  };
  log: string[];
  output: unknown;
  executionTime: number;
  request: string;
  placeholdersInfo?: PlaceholdersInfo;
};

export type OutputsDto = {
  [key: string]: ExecutionOutputDto;
};

export type VariablesDto = {
  [key: string]: unknown;
};

export type ExecutionContextDto = {
  globals: VariablesDto;
  outputs: OutputsDto;
  errorContext?: {
    actionId: string;
    actionName: string;
  };
  error?: string;
};

export interface ApiExecutionRequestDto {
  apiId: string;
  apiName?: string;
  params: ExecutionParamDto[];
  // TODO rename this
  viewMode: boolean;
}

export interface ApiExecutionSagaPayload extends ApiExecutionRequestDto {
  environment: string;
  eventType: EventType;
  notifyOnSystemError: boolean;
  // A flag that indicates if the API was kicked off manually by the user.
  manualRun: boolean;
  callStack: ExecuteActionCallStack;
  spanId?: string;
  commitId?: string;

  formatCode?: boolean;
}

export type ApiExecutionResponseDto = {
  statusCode: string;
  context: ExecutionContextDto;
  systemError: string;
  apiId?: string;
  apiName?: string;
  notificationConfig?: ApiNotificationConfiguration;
  executionError?: string;
};
