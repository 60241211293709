import { Classes } from "@blueprintjs/core";
import { CSSProperties } from "react";
import styled, { css } from "styled-components";
import { LegacyNamedColor } from "legacy/constants/LegacyNamedColors";
import { GeneratedTheme } from "legacy/themes";
import { styleAsClass } from "styles/styleAsClass";
import {
  TableSizes,
  SingleCellProperties,
  ColumnProperties,
  FontStyleTypes,
  CompactMode,
  CompactModeTypes,
  TABLE_SIZES,
  CellAlignmentTypes,
  JUSTIFY_CONTENT,
  ALIGN_ITEMS,
  TEXT_SIZES,
  TEXT_ALIGN,
} from "./Constants";

interface TableWrapperProps {
  width: number;
  height?: number;
  tableSizes: TableSizes;
  backgroundColor?: LegacyNamedColor;
  showColumnBorders: boolean;
  showFrozenColShadow?: boolean;
  colors: GeneratedTheme["colors"];
}

const TABLE_LEFT_PADDING = 14;
const CELL_HORIZONTAL_PADDING = 8;

export const TableWrapper = styled.div<TableWrapperProps>`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  overflow: hidden;
  .tableWrap {
    overflow-y: hidden;
    border-spacing: 0;
    flex-grow: 1;

    table {
      width: 100%;
    }
    [data-sticky-td] {
      position: sticky;
      left: 0;
    }
    [data-sticky-last-left-td] {
      ${(props) =>
        props.showFrozenColShadow
          ? css`
              box-shadow: 0px 1px 3px rgba(34, 39, 47, 0.06),
                0px 4px 16px rgba(34, 39, 47, 0.08);
              clip-path: inset(0px -15px 0px 0px);
            `
          : css`
              &:not(.td-focused) {
                border-right: ${props.showColumnBorders ? 2 : 1}px solid
                  ${props.colors.neutral100} !important;
              }
            `}
    }
    thead {
      z-index: 4 !important;
    }
    tbody {
      position: relative;
      tr {
        border-bottom: 1px solid ${(props) => props.colors.neutral100};
        :last-child {
          ${(props) =>
            props.showColumnBorders
              ? `border-bottom: 1px solid ${props.colors.neutral100};`
              : "border-bottom: none"};
        }
      }

      .tr {
        position: relative;
        width: 100%;
        cursor: pointer;
        &.deleted-row {
          .td:not([data-sticky-td]):not(.td-checkbox) {
            opacity: 0.5;
          }
          .td[data-sticky-td] {
            * {
              opacity: 0.5;
            }
          }
        }
        .table-row-context-menu {
          visibility: hidden;
          &.context-menu-open {
            visibility: visible;
          }
        }

        &.selected-row {
          background: ${(props) => props.colors.neutral50};
          [data-sticky-td] {
            background: ${(props) => props.colors.neutral50};
          }
          &:hover {
            background: ${(props) => props.colors.neutral50};
            [data-sticky-td] {
              background: ${(props) => props.colors.neutral50};
            }
          }
        }
        &:hover {
          .table-row-context-menu {
            visibility: visible;
          }
          background: ${(props) => props.colors.neutral50};
          [data-sticky-td] {
            background: ${(props) => props.colors.neutral50};
          }
        }
        [data-sticky-td] {
          background: ${(props) => props.colors.neutral};
        }
      }
    }

    .thead {
      background: ${(props) => props.colors.neutral};
      &:hover {
        .tr {
          .th:not(:last-child) {
            border-right-color: ${(props) => props.colors.neutral100};
          }
        }
      }
      height: ${(props) => props.tableSizes.COLUMN_HEADER_HEIGHT}px;
      position: sticky;
      top: 0;
      .tr {
        height: 100%;
        ${(props) =>
          props.showColumnBorders &&
          `border-top: 1px solid ${props.colors.neutral100};`};
        .th {
          border-bottom: 1px solid ${(props) => props.colors.neutral100};
          border-right: 1px solid
            ${(props) =>
              props.showColumnBorders
                ? props.colors.neutral100
                : "transparent"};
          background: ${(props) => props.colors.neutral};
          &:last-child {
            border-right: none;
          }
        }
      }
    }

    .th,
    .td {
      margin: 0;
      padding: 9px ${CELL_HORIZONTAL_PADDING}px;
      position: relative;
      // LAURA CHECK THESE
      font-size: ${(props) =>
        props.theme.legacy.appStyles.table.row.fontSize}px;
      line-height: ${(props) => props.tableSizes.ROW_FONT_SIZE}px;
      ${(props) =>
        props.showColumnBorders &&
        `border-right: 1px solid ${props.colors.neutral100};`};

      :first-of-type {
        > .table-multi-select-checkbox {
          margin: auto ${CELL_HORIZONTAL_PADDING}px; // multi-select checkboxes
        }
        > div:first-child:not(.editable-cell):not(
            .table-multi-select-checkbox
          ) {
          padding-left: ${TABLE_LEFT_PADDING + CELL_HORIZONTAL_PADDING}px;
        }
        div:first-child.editable-cell {
          position: relative;
          left: -${CELL_HORIZONTAL_PADDING}px;
        }
        > div:first-child.editing-cell-wrapper {
          position: relative;
          left: ${TABLE_LEFT_PADDING}px;
          width: calc(100% - ${TABLE_LEFT_PADDING}px);
        }
      }
      /* :last-child :not(.td-focused) {
        border-right: 0;
      } */
      .resizer {
        display: inline-block;
        width: 10px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;
        ${"" /* prevents from scrolling while dragging on touch devices */}
        touch-action:none;
        &.isResizing {
          cursor: isResizing;
        }
      }
    }
    .th {
      padding: 0 ${CELL_HORIZONTAL_PADDING}px 0 0;
      height: ${(props) => props.tableSizes.COLUMN_HEADER_HEIGHT}px;
      line-height: ${(props) => props.tableSizes.COLUMN_HEADER_HEIGHT}px;
      background: ${(props) => props.colors.neutral};
      display: flex !important;

      // dragging styles are more important than anything else
      &.highlight-left {
        border-left: 2px solid ${(props) => props.colors.primary500} !important;
      }
      &.highlight-right {
        border-right: 2px solid ${(props) => props.colors.primary500} !important;
      }
      &.dragging-header {
        background: ${(props) => props.colors.neutral100} !important;
        border-radius: 4px;
        z-index: 100;
        width: 100%;
        text-overflow: none;
        overflow: none;
      }
    }
    .td {
      padding: ${(props) => props.tableSizes.ROW_PADDING}px 0px;
      min-height: ${(props) => props.tableSizes.ROW_HEIGHT}px;
      border: 1px solid transparent;
      border-right-color: ${(props) =>
        props.showColumnBorders ? props.colors.neutral100 : "transparent"};
      scroll-margin-top: ${(props) =>
        `${props.tableSizes.COLUMN_HEADER_HEIGHT}px`}; // used for scrolling cells into view
      display: flex;
      align-items: center;
      &[data-align="TOP"] {
        align-items: flex-start;
      }
      &[data-align="BOTTOM"] {
        align-items: flex-end;
      }
    }
    .td-focused {
      border: 1px solid ${(props) => props.colors.primary500};
    }
  }

  .draggable-header,
  .hidden-header {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-left: ${CELL_HORIZONTAL_PADDING}px;
    &.sorted {
      padding-left: 5px;
    }
  }
  .draggable-header {
    cursor: pointer;
    &.reorder-line {
      width: 1px;
      height: 100%;
    }
  }
  .hidden-header {
    opacity: 0.6;
  }
  .column-menu {
    cursor: pointer;
    height: ${(props) => props.tableSizes.COLUMN_HEADER_HEIGHT}px;
    line-height: ${(props) => props.tableSizes.COLUMN_HEADER_HEIGHT}px;
  }

  .input-group {
    height: ${(props) => props.tableSizes.COLUMN_HEADER_HEIGHT}px;
    line-height: ${(props) => props.tableSizes.COLUMN_HEADER_HEIGHT}px;
    padding: 0 5px;
  }
`;

export const buildCellStyles = ({
  cellTextStyle,
  fontStyle,
  textColor,
  horizontalAlignment,
  verticalAlignment,
  textSize,
  compactMode,
  typographyFlagEnabled,
}: {
  cellTextStyle?: SingleCellProperties["cellTextStyle"];
  fontStyle?: SingleCellProperties["fontStyle"];
  textColor?: SingleCellProperties["textColor"];
  horizontalAlignment?: SingleCellProperties["horizontalAlignment"];
  verticalAlignment?: SingleCellProperties["verticalAlignment"];
  textSize?: SingleCellProperties["textSize"];
  compactMode: CompactMode;
  typographyFlagEnabled?: boolean;
}): CSSProperties => {
  let fontSize: React.CSSProperties["fontSize"] =
    textSize && TEXT_SIZES[textSize]
      ? TEXT_SIZES[textSize]
      : TABLE_SIZES[compactMode]?.ROW_FONT_SIZE;
  if (typographyFlagEnabled) {
    if (cellTextStyle?.fontSize) {
      fontSize = cellTextStyle?.fontSize;
    } else if (cellTextStyle?.variant) {
      fontSize = undefined; // Rely on css class for variant
    }
  }

  let color: React.CSSProperties["color"] = textColor;
  if (typographyFlagEnabled) {
    if (cellTextStyle?.textColor?.default) {
      color = cellTextStyle.textColor.default;
    } else if (cellTextStyle?.variant) {
      color = undefined;
    }
  }

  let fontWeight: React.CSSProperties["fontWeight"] = fontStyle?.includes(
    FontStyleTypes.BOLD,
  )
    ? "bold"
    : undefined;
  if (typographyFlagEnabled) {
    if (cellTextStyle?.fontWeight) {
      fontWeight = cellTextStyle?.fontWeight;
    } else if (cellTextStyle?.variant) {
      fontWeight = undefined;
    }
  }

  let fontStyleToUse: React.CSSProperties["fontStyle"] = fontStyle?.includes(
    FontStyleTypes.ITALIC,
  )
    ? "italic"
    : undefined;
  if (typographyFlagEnabled) {
    if (cellTextStyle?.fontStyle) {
      fontStyleToUse = cellTextStyle?.fontStyle;
    } else if (cellTextStyle?.variant) {
      fontStyleToUse = undefined;
    }
  }

  let lineHeight: React.CSSProperties["lineHeight"] = undefined;
  if (typographyFlagEnabled) {
    if (cellTextStyle?.lineHeight) {
      lineHeight = cellTextStyle?.lineHeight;
    }
  }

  let letterSpacing: React.CSSProperties["letterSpacing"] = undefined;
  if (typographyFlagEnabled) {
    if (cellTextStyle?.letterSpacing) {
      letterSpacing = cellTextStyle?.letterSpacing;
    }
  }

  let textTransform: React.CSSProperties["textTransform"] = undefined;
  if (typographyFlagEnabled) {
    if (cellTextStyle?.textTransform) {
      textTransform = cellTextStyle?.textTransform;
    }
  }

  let fontFamily: React.CSSProperties["fontFamily"] = undefined;
  if (typographyFlagEnabled) {
    if (cellTextStyle?.fontFamily) {
      fontFamily = cellTextStyle?.fontFamily;
    }
  }

  const textDecoration: React.CSSProperties["textDecoration"] =
    fontStyle?.includes(FontStyleTypes.UNDERLINE) ? "underline" : undefined;

  return {
    fontWeight,
    color,
    fontStyle: fontStyleToUse,
    textDecoration,
    justifyContent: horizontalAlignment
      ? JUSTIFY_CONTENT[horizontalAlignment]
      : undefined,
    alignItems: verticalAlignment ? ALIGN_ITEMS[verticalAlignment] : undefined,
    fontSize,
    lineHeight,
    letterSpacing,
    textTransform,
    fontFamily,
    textAlign: horizontalAlignment
      ? (TEXT_ALIGN[horizontalAlignment] as CSSProperties["textAlign"])
      : undefined,
  };
};

export const CELL_WRAPPER_HOR_MARGIN = 8;

interface CellWrapperProps {
  isHidden?: boolean;
  cellProperties: SingleCellProperties;
  compactMode: CompactMode;
  maxWidth?: number;
  hasRightIcon?: boolean;
  hasLeftIcon?: boolean;
  isDropdownType?: boolean;
}

const TAG_HEIGHT = 28;

type CellOverflowOptions = {
  canWrap?: boolean;
  maxLines?: number;
  isTags?: boolean;
};

export const getCellWrapStyles = (options: CellOverflowOptions) => {
  if (options.isTags && options.maxLines != null && options.maxLines > 0) {
    const heightOfTags = options.maxLines * TAG_HEIGHT;
    return {
      maxHeight: `${heightOfTags}px`,
    };
  }
  if (!options.isTags && (!options.canWrap || options.maxLines === 1)) {
    return {
      WebkitLineClamp: 1,
    };
  }
  if (!options.isTags && options.maxLines != null && options.maxLines > 0) {
    return {
      WebkitLineClamp: options.maxLines,
    };
  }
};

const wrappedCellClass = styleAsClass`
  &.tags-cell {
    display: flex;
    flex-wrap: wrap;
    gap: 4px 0px;
  }
  &.overflow-hidden {
    overflow: hidden;
  }
  &.text-cell-limited {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-break: break-word;
    text-overflow: ellipsis;
    white-space: pre-wrap;

    .${Classes.POPOVER_TARGET} {
      display: block;
    }
  }
  &.text-cell-unlimited {
    overflow: visible;
    word-break: break-word;
    white-space: pre-wrap;
  }
`;

export const getCellClass = (options: CellOverflowOptions) => {
  if (options.isTags) {
    if (!options.canWrap || options.maxLines === 1) {
      return undefined; // no style changes needed
    } else if (options.maxLines != null && options.maxLines > 0) {
      return `${wrappedCellClass} tags-cell overflow-hidden `;
    } else {
      return `${wrappedCellClass} tags-cell`;
    }
  }
  if (
    !options.canWrap ||
    (options.canWrap && options.maxLines != null && options.maxLines > 0)
  ) {
    return `${wrappedCellClass} text-cell-limited overflow-hidden`;
  } else {
    return `${wrappedCellClass} text-cell-unlimited`;
  }
};

export const CellWrapper = styled.div<CellWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: ${(props) =>
    props.hasLeftIcon || props.hasRightIcon ? "auto" : "100%"};
  opacity: ${(props) => (props.isHidden ? "0.6" : "1")};

  padding: 0
    ${(props) =>
      (props?.cellProperties?.horizontalAlignment === CellAlignmentTypes.RIGHT
        ? CELL_HORIZONTAL_PADDING
        : // 8px for dropdown icon
          CELL_WRAPPER_HOR_MARGIN) +
      (props.isDropdownType && !props.hasRightIcon ? 8 : 0)}px
    0 ${CELL_WRAPPER_HOR_MARGIN}px;

  ${(props) =>
    props.maxWidth !== undefined
      ? css`
          max-width: ${props.maxWidth}px;
        `
      : ""}

  .image-cell-wrapper {
    &[data-image-size="FIXED"] {
      ${(props) => {
        const imageSize =
          props.compactMode && TABLE_SIZES[props.compactMode]
            ? TABLE_SIZES[props.compactMode].IMAGE_SIZE
            : TABLE_SIZES[CompactModeTypes.DEFAULT].IMAGE_SIZE;
        return css`
          height: ${imageSize}px;
          width: ${imageSize}px;
        `;
      }}
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    &[data-image-size="FIT"] {
      ${(props) => {
        const imageSize =
          props.compactMode && TABLE_SIZES[props.compactMode]
            ? TABLE_SIZES[props.compactMode].IMAGE_SIZE
            : TABLE_SIZES[CompactModeTypes.DEFAULT].IMAGE_SIZE;
        return css`
          height: ${imageSize}px;
        `;
      }}
      width: 100%;
      overflow: hidden;
      img {
        max-width: 100%;
        max-height: 100%;
        height: auto;
      }
    }

    &[data-image-size="COVER"] {
      ${(props) => {
        const imageSize =
          props.compactMode && TABLE_SIZES[props.compactMode]
            ? TABLE_SIZES[props.compactMode].IMAGE_SIZE
            : TABLE_SIZES[CompactModeTypes.DEFAULT].IMAGE_SIZE;
        return css`
          height: ${imageSize}px;
        `;
      }}
      width: 100%;
      position: relative;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &[data-image-size="GROW"] {
      width: 100%;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  video {
    border-radius: 4px;
  }
  &.video-cell {
    height: 100%;
    iframe {
      border: none;
      border-radius: 4px;
    }
  }
  a {
    width: 100%;
    display: flex;
    justify-content: ${(props) =>
      props?.cellProperties?.horizontalAlignment &&
      JUSTIFY_CONTENT[props?.cellProperties?.horizontalAlignment]};
  }
  line-height: 1.5;
`;

export const ColumnHeaderWrapper = styled.div<{
  columnProperties: ColumnProperties;
}>`
  display: flex;
  gap: 4px;
  justify-content: ${(props) =>
    props?.columnProperties?.horizontalAlignment &&
    JUSTIFY_CONTENT[props?.columnProperties?.horizontalAlignment]};
  align-items: center;
  overflow: hidden;
`;

export const ColumnHeaderTitleWrapper = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: no-wrap;
`;

export const HeaderCellContentWrapper = styled.div<{
  columnProperties: ColumnProperties;
  shouldCapitalize: boolean;
}>`
  display: flex;
  flex: 1;
  justify-content: ${(props) =>
    props?.columnProperties?.horizontalAlignment &&
    JUSTIFY_CONTENT[props?.columnProperties?.horizontalAlignment]};

  span {
    ${({ shouldCapitalize }) =>
      shouldCapitalize && `text-transform: capitalize;`}
  }
`;

interface TableIconWrapperProps {
  selected?: boolean;
  disabled?: boolean;
  tableSizes: TableSizes;
}

export const TableIconWrapper = styled.div<TableIconWrapperProps>`
  width: ${(props) => props.tableSizes.TABLE_FOOTER_HEIGHT}px;
  height: ${(props) => props.tableSizes.TABLE_FOOTER_HEIGHT}px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => !props.disabled && "pointer"};
  position: relative;
`;

export const SortIconWrapper = styled.div`
  display: inline-flex;
  justify-items: center;
  align-items: center;
  padding-left: 8px;
`;

export const DefaultValueWrapper = styled.div`
  color: ${(props) => props.theme.colors.GREY_300};
`;

export const LeftColumnIconWrapper = styled.div<{
  cellProperties: SingleCellProperties;
}>`
  margin-right: -6px;
  padding-left: 8px;
  ${(props) => {
    if (props.cellProperties?.textColor) {
      return css`
        color: ${props.cellProperties?.textColor};
      `;
    }
  }}
`;
export const RightColumnIconWrapper = styled.div<{
  cellProperties: SingleCellProperties;
  isDropdownType: boolean;
}>`
  margin-left: ${(props) => {
    if (
      props.cellProperties?.horizontalAlignment === CellAlignmentTypes.RIGHT
    ) {
      return "-12px";
    }
    return "-6px";
  }};
  padding-right: ${(props) => {
    if (props.isDropdownType) {
      return "28px";
    }
    return "8px";
  }};
  ${(props) => {
    if (props.cellProperties?.textColor) {
      return css`
        color: ${props.cellProperties?.textColor};
      `;
    }
  }}
`;

export const TextIconWrapper = styled.div<{
  cellProperties: SingleCellProperties;
  hasLeftIcon?: boolean;
  hasRightIcon?: boolean;
}>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props?.cellProperties?.horizontalAlignment &&
    JUSTIFY_CONTENT[props?.cellProperties?.horizontalAlignment]};
`;
