import { Classes } from "@blueprintjs/core";
import { Classes as MultiSelectClasses } from "@blueprintjs/select";
import { createGlobalStyle } from "styled-components";
import { CLASS_NAMES } from "legacy/themes/classnames";
import { palettes } from "./antd/antd";
import { colors } from "./colors";

export const AntdSelectStyles = createGlobalStyle`
  .ant-select-dropdown .rc-virtual-list-holder-inner {
    margin: 0 4px !important; // style of margin-left: 0 gets added as a style by antd
  }

  .ant-select-selection-item {
    font-size: 12px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.GREY_700};
  }

  .ant-select-disabled {
    .ant-select-selection-item {
      color: rgba(0, 0, 0, 0.25);
    }
  }

  .ant-select-item {
    font-size: 12px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.GREY_700};
    border-radius: 4px;

    &:hover {
      background: ${({ theme }) => theme.colors.GREY_50};
    }
  }
`;

export const AntdDropdownStyles = createGlobalStyle`
  .ant-dropdown-menu-item-group {
    border-bottom: 1px solid ${({ theme }) => theme.colors.GREY_100};
    padding: 4px 0;
  }
  .ant-dropdown-menu-item-group-title {
    font-size: 12px;
    line-height: 26px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.GREY_500};
  }
  .ant-dropdown-menu-submenu-title,
  .ant-dropdown-menu-item {
    color: ${({ theme }) => theme.colors.GREY_500};
    padding: 5px 10px;
  }
  .ant-dropdown-menu-submenu-title {
    /* to fit right arrow */
    padding-right: 24px;
  }
  .ant-dropdown-menu-item-active,
  .ant-dropdown-menu-item:hover {
    background-color: ${({ theme }) => theme.colors.GREY_50};
  }
  .ant-dropdown-menu-item-group-list > .ant-dropdown-menu-item {
    font-size: 12px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.GREY_700};
    transition: color 0.2s;
  }
  .ant-select-item-group {
    color: rgba(0, 0, 0, 0.45);
  }
  .ant-select-item-group:hover {
    background-color: transparent;
  }

  .ant-dropdown-menu-submenu,
  .ant-dropdown-menu-item {
    margin: 0 4px;
  }
  .ant-dropdown-menu-item-group-list {
    margin: 0px;
  }
  .ant-dropdown-menu-submenu-active > .ant-dropdown-menu-submenu-title,
  .ant-dropdown-menu-item-active {
    background-color: ${colors.ACCENT_BLUE_500_50};
    border-radius: 4px;
  }
  .ant-dropdown-menu-submenu-active
    > .ant-dropdown-menu-submenu-title
    > .ant-dropdown-menu-title-content {
    color: ${({ theme }) => theme.colors.ACCENT_BLUE_500};
  }
  .ant-dropdown-menu-submenu.new-api-submenu > ul {
    max-height: 310px;
    overflow-y: auto;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background: ${colors.ACCENT_BLUE_500_50};
    font-weight: 400;
  }

  // styles for antd menu that is not in dropdown, such as for creating new apis
  .${CLASS_NAMES.DROPDOWN_MENU} .ant-menu {
    padding: 4px 0px;
  }
  .${CLASS_NAMES.DROPDOWN_MENU}.ant-menu-vertical {
    border: none;
  }


  .${CLASS_NAMES.DROPDOWN_MENU} .ant-menu-item-group {
  border-bottom: 1px solid ${({ theme }) => theme.colors.GREY_100};
  padding: 4px 0;
  }
  .${CLASS_NAMES.DROPDOWN_MENU} .ant-menu-item-group-title {
    font-size: 12px;
    line-height: 26px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.GREY_500};
  }
  .${CLASS_NAMES.DROPDOWN_MENU} .ant-menu-submenu-title,
  .${CLASS_NAMES.DROPDOWN_MENU} .ant-menu-item,
  .${CLASS_NAMES.DROPDOWN_MENU} .ant-submenu-item,
  .${CLASS_NAMES.DROPDOWN_MENU} .ant-menu-vertical .ant-menu-item {
    color: ${({ theme }) => theme.colors.GREY_700};
    font-weight: 400;
    font-size: 12px;
    padding: 5px 10px;
  }

  .${CLASS_NAMES.DROPDOWN_MENU} .ant-menu-item-active,
  .${CLASS_NAMES.DROPDOWN_MENU} .ant-dropdown-menu-item-active  {
    color: ${colors.ACCENT_BLUE_500};
    background-color: ${colors.ACCENT_BLUE_500_50}
  }

  .${CLASS_NAMES.DROPDOWN_MENU} .ant-menu-submenu {
    padding: 0px 4px;
  }

  .${
    CLASS_NAMES.DROPDOWN_MENU
  }:first-child .ant-menu-submenu > .ant-menu-submenu-title {
    margin: 0px;
  }
  .${
    CLASS_NAMES.DROPDOWN_MENU
  } .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-title-content {
    span {
      line-height: 24px;
    }
  }

  .${CLASS_NAMES.DROPDOWN_MENU}.ant-menu-submenu > .ant-menu-submenu-title,
  .${
    CLASS_NAMES.DROPDOWN_MENU
  }.ant-menu-submenu .ant-dropdown-menu-title-content,
  .${CLASS_NAMES.DROPDOWN_MENU} .ant-menu-item-group-list > .ant-menu-item,
  .${CLASS_NAMES.DROPDOWN_MENU} .ant-menu-submenu .ant-menu-title-content {
    font-size: 12px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.GREY_700};
    transition: color 0.2s;
    span {
      line-height: 27px;
    }
  }
  .${CLASS_NAMES.DROPDOWN_MENU} .ant-select-item-group {
    color: rgba(0, 0, 0, 0.45);
  }
  .${CLASS_NAMES.DROPDOWN_MENU} .ant-select-item-group:hover {
    background-color: transparent;
  }

  .${CLASS_NAMES.DROPDOWN_MENU}.ant-menu-submenu,
  .${CLASS_NAMES.DROPDOWN_MENU} .ant-menu-item {
    margin: 0px 4px;
  }
  .${CLASS_NAMES.DROPDOWN_MENU} .ant-menu-item-group-list {
    margin: 0px;
  }
  .${
    CLASS_NAMES.DROPDOWN_MENU
  }.ant-menu-submenu-active > .ant-menu-submenu-title,
  .${
    CLASS_NAMES.DROPDOWN_MENU
  } .ant-menu-submenu-active > .ant-menu-submenu-title,
  .${CLASS_NAMES.DROPDOWN_MENU} .ant-menu-item-active {
    background-color: ${colors.ACCENT_BLUE_500_50};
    border-radius: 4px;
  }
  .${CLASS_NAMES.DROPDOWN_MENU}.ant-menu-submenu-active
    > .ant-menu-submenu-title
    > .ant-menu-title-content,
  .${CLASS_NAMES.DROPDOWN_MENU} .ant-menu-submenu-active
    > .ant-menu-submenu-title
    > .ant-menu-title-content,
  .${CLASS_NAMES.DROPDOWN_MENU} .ant-menu-submenu-active
    > .ant-menu-submenu-title
    > .ant-menu-submenu-arrow {
    color: ${colors.ACCENT_BLUE_500}
  }
  .${CLASS_NAMES.DROPDOWN_MENU}.ant-menu-submenu.new-api-submenu > ul {
    max-height: 310px;
    overflow-y: auto;
  }
  .${
    CLASS_NAMES.DROPDOWN_MENU
  } .ant-menu-vertical .ant-menu-item:not(:last-child) {
    margin-bottom: 0px;
  }

  .${CLASS_NAMES.DROPDOWN_MENU} .ant-menu-vertical > .ant-menu-item,
  .${
    CLASS_NAMES.DROPDOWN_MENU
  }.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title {
    line-height: 22px;
    height: 32px;
  }
  .${CLASS_NAMES.DROPDOWN_MENU} .ant-menu-vertical.ant-menu-sub .ant-menu-item {
    margin-left: 3px;
  }
  .${CLASS_NAMES.DROPDOWN_MENU} .ant-menu-item .ant-menu-item-icon + span {
    margin-left: 0px;
  }
`;

export const AntdNoPaddingDropdownStyles = createGlobalStyle`
  .no-padding-dropdown {
    padding: 0px;
  }
  .ant-select-dropdown.no-padding-dropdown .rc-virtual-list-holder-inner {
    margin: 0px;
  }
  .no-padding-dropdown .ant-select-item {
    border-radius: 0px;
  }
  .no-padding-dropdown .ant-select-item.ant-select-item-option{
    padding-left: 16px;
  }
`;

export const AntdNoPaddingPopoverStyles = createGlobalStyle`
  .no-padding-popover .ant-popover-inner-content {
    padding: 0;
  }
`;

export const AntdDatePickerStyles = createGlobalStyle`
  .ant-picker-dropdown {
    font-size: 12px;
  }
`;

export const BlueprintSelectStyles = createGlobalStyle`
  .${Classes.OVERLAY} .${Classes.POPOVER_TRANSITION_CONTAINER} {
    margin: 4px 0;
  }

  .${MultiSelectClasses.MULTISELECT} {
      border-radius: 4px;
      border: 1px solid ${(props) => props.theme.colors.GREY_100};
      min-height: 36px;
      svg[data-icon="chevron-down"] {
        display: none;
      }
  }

  .${Classes.POPOVER_OPEN} .${MultiSelectClasses.MULTISELECT},
  .${Classes.POPOVER_OPEN} .${Classes.BUTTON} {
    border-color: ${(props) => props.theme.colors.ACCENT_BLUE_500};
    svg[data-icon="chevron-down"] {
        display: none;
    }
  }

  .dropdown-popover-wrapper {
    border-radius: 4px;
    border: none;

    // submenus
    &&&& .${Classes.POPOVER_CONTENT} {
      border-radius: 4px;
      border: none;

      .${Classes.MENU_SUBMENU} .${Classes.MENU} {
        padding: 6px 0;
      }
    }
  }

  .select-popover-wrapper {
    width: 100%;
    border-radius: ${(props) => props.theme.legacy.radii[2]}px;
    box-shadow: 0px 1px 3px rgba(34, 39, 47, 0.06), 0px 12px 32px -8px rgba(34, 39, 47, 0.16), 0px 0px 1px rgba(34, 39, 47, 0.16);
    border-radius: 2px;
    padding: 0px;
    margin: 4px 0;
    font-size: ${(props) => props.theme.legacy.appStyles.dropdown.fontSize}px;
    background: white;

    .${Classes.INPUT_GROUP} {
      .${Classes.ICON} {
        color: ${({ theme }) => theme.colors.GREY_300};
        margin: 10px;
      }
      .${Classes.INPUT_ACTION} {
        margin: 3px 5px;
      }
      .${Classes.INPUT} {
        box-shadow: none;
        border-bottom: 1px solid ${({ theme }) => theme.colors.GREY_100};
        border-radius: 0;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        height: 36px;
        line-height: 36px;
        padding-left: 32px;
      }
    }

    && .${Classes.MENU} {
      max-width: 100%;
      max-height: auto;
      padding: 4px;
    }

    &&&& .${Classes.MENU_ITEM}.single-select {
        padding: 6px 12px;
        display: flex;
        gap: 5px;
    }

    &&&& .${Classes.MENU_ITEM} {
      margin: 2px 0;
      color: ${(props) => props.theme.colors.GREY_700};
      border-radius: ${(props) => props.theme.legacy.radii[2]}px;
      font-size: 12px;
      font-weight: 400;
      padding: 0;
      &:hover {
        background: ${(props) => props.theme.colors.GREY_50};
        .${Classes.CONTROL} .${Classes.CONTROL_INDICATOR} {
          border-color: ${(props) => props.theme.colors.ACCENT_BLUE_500};
        }
      }
      &.${Classes.ACTIVE} {
        background: ${(props) => props.theme.colors.GREY_50};
        position: relative;
      }
      &.single-select.selected, &.multi-select.selected {
        background: ${colors.ACCENT_BLUE_500_50};
      }
      .${Classes.CONTROL} .${Classes.CONTROL_INDICATOR} {
        background: white;
        box-shadow: none;
        border-width: 1px;
        border-style: solid;
        border-color: ${(props) => props.theme.colors.GREY_100};
        border-radius: 4px;
        font-size: 18px;
        padding: 2px;
        &::before {
          width: auto;
          height: .75em;
        }
      }
      .${Classes.CONTROL} input:checked ~ .${Classes.CONTROL_INDICATOR} {
        background: ${(props) => props.theme.colors.ACCENT_BLUE_500};
        color: ${(props) => props.theme.colors.WHITE};
        border-color: ${(props) => props.theme.colors.ACCENT_BLUE_500};
      }
    }

  }

  .flash {
    border-color: ${(props) => props.theme.colors.ACCENT_ORANGE} !important;
    box-shadow: 0px 0px 4px 0.5px ${(props) =>
      props.theme.colors.ACCENT_BLUE_600} !important;
  }
`;

const antdAccentRowClassName = "accent-row";
export const AntdTableRowStyle = createGlobalStyle`
  .${antdAccentRowClassName} {
    td:first-child {
      border-left: 3px solid ${palettes["@blue-base"]};
      background: linear-gradient(90deg, rgba(39, 187, 255, 0.12) 0%, rgba(39, 187, 255, 0) 100%);
      color: ${palettes["@blue-base"]};
    }
  }
`;

export const BlueprintTooltipStyles = createGlobalStyle`
  .bp5-tooltip {
    border-radius: 6px;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);

    .bp5-popover-arrow {
      display: block;
    }
    .bp5-popover-arrow-fill {
      fill: ${({ theme }) => theme.colors.BLACK};
    }
    .bp5-popover-content {
      border-radius: 6px;
      padding: 6px 10px;
      background: ${({ theme }) => theme.colors.BLACK};
    }
  }
`;

export const BlueprintPopoverStyles = createGlobalStyle`
  .error-tooltip-popover {
    /* This is overriden by the themed popover styles inside iframe */
    background: white;
  }
  .${CLASS_NAMES.POPOVER_WRAPPER}.error-tooltip-popover {
    padding: 8px;
    text-align: center;
    text-transform: initial;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.DANGER};

    ul {
      color: ${({ theme }) => theme.colors.DANGER};
      padding-left: 12px;
      margin: 0;
      text-align: left;
    }
  }
`;

export const AntModalStyles = createGlobalStyle`
  .ant-modal-wrap {
    overflow: hidden;
    display: flex;
    align-items: center;
  }

  .ant-modal {
    top: auto;
    padding: 0;
  }

  .ant-modal-content {
    height: 100%;
  }
`;

export const AntdTooltipStyles = createGlobalStyle`
  .error-tooltip {
    .ant-tooltip-inner {
      // match styles of legacy ErrorTooltip component (.error-tooltip-popover)
      background: ${(props) => props.theme.colors.WHITE};
      color: ${({ theme }) => theme.colors.DANGER};
      text-align: center;
      text-transform: initial;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      opacity: 1;
      box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2);
      display: flex;
      align-items: center;
      border-radius: 3px;
    }
    .ant-tooltip-arrow {
      display: none;
    }
  }

  .no-padding-tooltip {
    .ant-tooltip-inner {
      padding: 0px;
      width: max-content;
    }
  }

  .ant-tooltip-inner {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.GREY_100};
    border-radius: 6px;
    padding-top: 7px;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  }
  .ant-tooltip-inner, .ant-tooltip-arrow-content {
    background-color: ${(props) => props.theme.colors.BLACK};
  }
`;

// tox is used to display the file picker for the rich text editor
export const TinyMceStyles = createGlobalStyle`
  .tox-collection__item-icon {
    svg path {
     fill: ${({ theme }) => theme.colors.GREY_300};
    }
    svg.no-fill path {
      fill: transparent;
    }
  }
    .tox-collection__item {
    fill: ${({ theme }) => theme.colors.GREY_300};
    color: ${({ theme }) => theme.colors.GREY_500};
  }

  .tox-toolbar__overflow {
    .tox-icon svg path {
      fill: ${({ theme }) => theme.colors.GREY_300};
    }
    .tox-icon svg.no-fill path {
      fill: transparent;
    }
  }
`;

export const AntdPopconfirmStyles = createGlobalStyle`

.ant-popconfirm {
  max-width: 200px;
  .ant-popover-arrow {
    display: none;
  }
  .ant-popover-inner {
    box-shadow: 0px 0px 1px 0px rgba(34, 39, 47, 0.16), 0px 12px 32px -8px rgba(34, 39, 47, 0.16), 0px 1px 3px 0px rgba(34, 39, 47, 0.06);

    .ant-popover-inner-content {
      padding: 12px;
      .ant-popover-message {
        padding-top: 0px;
        > .anticon {
          top: 4px;
        }
        .ant-popover-message-title {
        font-size: 13px;
        color: ${({ theme }) => theme.colors.GREY_500};
          line-height: 20px;
        text-align: left;
      }
      }

    }
    .ant-popover-buttons {
      display: flex;
      justify-content: end;
      margin-bottom: 0px;
      button:first-child {
        margin-left: 0px;
      }
      button:not(:first-child) {
        margin-left: 12px;
      }
    }
  }

  }

  .no-icon-popconfirm {
    .ant-popover-message-title {
      padding-left: 0px;
    }
  }
  `;

export const AntdButtonStyles = createGlobalStyle`
    .ant-btn-dangerous.ant-btn-primary {
      border-color: ${({ theme }) => theme.colors.DANGER};
      background:  ${({ theme }) => theme.colors.DANGER};
      &:hover {
        border-color: ${({ theme }) => theme.colors.DANGER};
        background:  ${({ theme }) => theme.colors.DANGER};
        opacity: 0.7;
      }
    }
    .ant-btn-dangerous.ant-btn-secondary {
      border-color: ${({ theme }) => theme.colors.DANGER};
      &:hover {
        border-color: ${({ theme }) => theme.colors.DANGER};
        opacity: 0.7;
      }
    }
  `;
