import { Tooltip } from "@blueprintjs/core";
import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { TextStyleWithVariant } from "legacy/themes";
import { CLASS_NAMES } from "legacy/themes/classnames";
import { getTableCellTextStyleClassName } from "legacy/widgets/TableWidget/TableComponent/TableHelpers";
import { useAppSelector } from "store/helpers";
import { Flag, selectFlagById } from "store/slices/featureFlags";
import {
  SingleCellProperties,
  CompactMode,
  DEFAULT_CELL_CLASS_NAME,
} from "./Constants";
import {
  CellWrapper,
  getCellClass,
  getCellWrapStyles,
  buildCellStyles,
} from "./TableStyledWrappers";
import type { AppState } from "store/types";

const TooltipContentWrapper = styled.div<{ width: number }>`
  word-break: break-all;
  max-width: ${(props) => props.width}px;
`;

const AutoToolTipComponent = (props: {
  isHidden?: boolean;
  children: React.ReactNode;
  title: string;
  cellProperties: SingleCellProperties;
  tableWidth?: number;
  compactMode: CompactMode;
  handleCellFocus?: () => void;
  maxLinesPerRow?: number;
  hasLeftIcon?: boolean;
  hasRightIcon?: boolean;
  isDropdownType?: boolean;
  tableCellTextStyle?: TextStyleWithVariant;
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [useToolTip, updateToolTip] = useState(false);
  useEffect(() => {
    const element = ref.current;
    const shouldUseTooltip =
      !!element &&
      (element.offsetHeight < element.scrollHeight ||
        element.offsetWidth < element.scrollWidth);

    updateToolTip(shouldUseTooltip);
  }, []);

  const { cellProperties, compactMode, maxLinesPerRow } = props;

  const {
    cellTextStyle,
    fontStyle,
    textColor,
    horizontalAlignment,
    verticalAlignment,
    textSize,
    textWrap,
  } = cellProperties;

  const typographyFlagEnabled = useAppSelector((state: AppState) =>
    selectFlagById(state, Flag.ENABLE_TYPOGRAPHY),
  );

  const [className, style] = useMemo(() => {
    const cellOptions = {
      canWrap: textWrap,
      maxLines: maxLinesPerRow,
    };
    return [
      getCellClass(cellOptions),
      {
        ...getCellWrapStyles(cellOptions),
        ...buildCellStyles({
          cellTextStyle,
          fontStyle,
          textColor,
          horizontalAlignment,
          verticalAlignment,
          textSize,
          compactMode,
          typographyFlagEnabled: Boolean(typographyFlagEnabled),
        }),
      },
    ];
  }, [
    compactMode,
    maxLinesPerRow,
    textWrap,
    cellTextStyle,
    fontStyle,
    textColor,
    horizontalAlignment,
    verticalAlignment,
    textSize,
    typographyFlagEnabled,
  ]);

  // Start with the default text style for cells
  let cellClassName = DEFAULT_CELL_CLASS_NAME;

  if (typographyFlagEnabled) {
    const variantClassName = getTableCellTextStyleClassName({
      tableCellTextStyleVariant: props.tableCellTextStyle?.variant,
      cellTextStyleVariant: props.cellProperties.cellTextStyle?.variant,
    });
    if (variantClassName) {
      cellClassName = variantClassName;
    }
  }

  return (
    <CellWrapper
      cellProperties={props.cellProperties}
      ref={ref}
      isHidden={props.isHidden}
      compactMode={props.compactMode}
      onClick={props.handleCellFocus}
      className={`${className} ${cellClassName}`}
      style={style}
      hasLeftIcon={props.hasLeftIcon}
      hasRightIcon={props.hasRightIcon}
      isDropdownType={props.isDropdownType}
    >
      {useToolTip && props.children ? (
        <Tooltip
          autoFocus={false}
          hoverOpenDelay={1000}
          popoverClassName={CLASS_NAMES.POPOVER_WRAPPER}
          content={
            <TooltipContentWrapper
              width={(props.tableWidth || 300) - 32}
              className={CLASS_NAMES.BUILTIN_BODY}
            >
              {props.title}
            </TooltipContentWrapper>
          }
          position="top"
        >
          {props.children}
        </Tooltip>
      ) : (
        props.children
      )}
    </CellWrapper>
  );
};

export default AutoToolTipComponent;
