import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

const LinkStyles = css`
  &:hover {
    text-decoration: none;
  }
`;
export const PlainLink = styled.a`
  ${LinkStyles}
`;

export default styled(Link)`
  ${LinkStyles}
`;
