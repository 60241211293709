import { OnboardingTask } from "@superblocksteam/shared";
import { createSelector } from "reselect";
import { tasks } from "pages/components/Checklist/tasks";
import slice from "./slice";

export const selectUserChecklist = createSelector(
  slice.selector,
  (state) => state.entity.checklistTasks,
);

export const selectVisibleChecklist = createSelector(
  selectUserChecklist,
  (checklist) => {
    return tasks
      .filter((task) => !task.hidden)
      .map((task) => {
        const checklistItem: OnboardingTask | undefined = checklist?.find(
          (i) => i.id === task.id,
        );
        return {
          ...task,
          completed: checklistItem?.completed,
          viewed: checklistItem?.viewed,
        };
      })
      .sort(
        (x, y) =>
          (x.completed?.getTime?.() ?? Number.MAX_VALUE) -
          (y.completed?.getTime?.() ?? Number.MAX_VALUE),
      );
  },
);

export const selectLastCompletedTask = createSelector(
  selectVisibleChecklist,
  (checklist) => {
    return checklist
      .slice()
      .reverse()
      .find((task) => task.completed);
  },
);

export const selectRecommendedDatasources = createSelector(
  slice.selector,
  (state) => state.entity.recommendedDatasources,
);
